import React, { useState, useEffect } from 'react';
import TopCards from '../components/TopCards';
import { MainCardsLayout } from '../components/MainCards';
import axios from 'axios';
import supabase from '../Auth/supabase';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface DashboardProps {
  okrs: OKR[];
  onStatusClick: React.Dispatch<React.SetStateAction<string | null>>;
  selectedOkrs: OKR[];
  selectedStatus: string | null;
}

const Dashboard: React.FC<DashboardProps> = ({ okrs, onStatusClick, selectedOkrs, selectedStatus }) => {
  const [goalType, setGoalType] = useState<string>('Company');
  const [allOkrs, setAllOkrs] = useState<OKR[]>([]); // Store all OKRs fetched from the backend
  const [filteredOkrs, setFilteredOkrs] = useState<OKR[]>([]); // Store the filtered OKRs based on user selections
  const [selectedTeam, setSelectedTeam] = useState<string>('All Teams');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  // Fetch OKRs once when the component mounts
  useEffect(() => {
    const fetchOKRs = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const token = session?.access_token;

        if (token) {
          const response = await axios.get(`${apiBaseUrl}/api/okrs`, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json'  
            }
          });
          
          if (Array.isArray(response.data)) {
            setAllOkrs(response.data); // Store the fetched OKRs in allOkrs state
          } else {
            console.error("Expected an array but received:", response.data);
            setAllOkrs([]); // Fallback to an empty array
          }
        } else {
          console.error("No auth token found");
          setError("No auth token found");
        }
      } catch (error) {
        console.error("Failed to fetch OKRs:", error);
        setError("Failed to fetch OKRs");
      }
    };
  
    fetchOKRs();
  }, []); // This effect runs only once when the component mounts
  
  // Filter OKRs based on the current selection
  useEffect(() => {
    let filtered = allOkrs.filter(okr =>
      (selectedStatus ? okr.Status === selectedStatus : true) &&
      (selectedTeam === 'All Teams' || okr.Team === selectedTeam) &&
      (!selectedUser || okr.AssigneeName === selectedUser)
    );

    if (goalType !== 'Individual') {
      filtered = filtered.filter(okr => okr.GoalType === goalType);
    }

    setFilteredOkrs(filtered); // Update the filteredOkrs state
  }, [allOkrs, goalType, selectedStatus, selectedTeam, selectedUser]); // Depend on allOkrs and the other selection states

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="card bg-base-100 shadow-xl">
      <TopCards
        setGoalType={setGoalType}
        selectedGoalType={goalType}
        setSelectedTeam={setSelectedTeam}
        selectedTeam={selectedTeam}
        setSelectedUser={setSelectedUser}
        selectedUser={selectedUser}
        setSelectedStatus={onStatusClick}
      />
      <MainCardsLayout
        goalType={goalType}
        selectedOkrs={filteredOkrs}
        onStatusClick={onStatusClick}
        selectedStatus={selectedStatus}
        selectedTeam={selectedTeam}
        selectedUser={selectedUser}
      />
    </div>
  );
}

export default Dashboard;
