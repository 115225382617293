import React from 'react';
import { format, parseISO } from 'date-fns';
import '../App.css';

interface OKR {
  Goal: string;
  GoalType: string;
  Team?: string;
  AssigneeName?: string;
  TimePeriod: string;
  Status: string;
  completionDate?: string;
  failureDate?: string;
  Comments?: string;
}

interface FokusOKRTileProps {
  okr: OKR;
}

const FokusOKRTile: React.FC<FokusOKRTileProps> = ({ okr }) => {
  // Determine border color based on the status of the goal
  let borderColor;
  switch (okr.Status) {
    case 'Red':
      borderColor = 'red'; // Keep red for 'Red' status
      break;
    case 'Completed':
      borderColor = 'green'; // Set to green if completed
      break;
    case 'Failed':
      borderColor = 'red'; // Set to red if rejected
      break;
    default:
      borderColor = '#FFBF00'; // Default for Amber and other statuses
      break;
  }

  // Helper function to format the date
  const formatDate = (dateString: string): string => {
    // Parse the ISO string to a Date object
    const date = parseISO(dateString);
    // Format the date to "d-MMM-yyyy" (e.g., "3-Mar-2024")
    return format(date, 'd-MMM-yyyy');
  };

  return (
    <div style={{ 
      border: `1px solid ${borderColor}`,
      margin: '10px', 
      padding: '10px', 
      borderRadius: '8px', 
      backgroundColor: '#fff'  // Ensure background contrasts well with border
    }}>
      <h3>{okr.Goal}</h3>
      {okr.GoalType === 'Company' &&  <p>Type: {okr.GoalType}</p>} 
      {okr.GoalType === 'Team' && <p>Team: {okr.Team}</p>}  
      {okr.GoalType !== 'Company' && (
        <p>Owner: {okr.AssigneeName}</p>  // Conditionally render the Owner unless it's a Company goal
      )}
      <p>Deadline: {formatDate(okr.TimePeriod)}</p> 
      {okr.Status === 'Completed' && okr.completionDate && <p>Date Marked Complete: {formatDate(okr.completionDate)}</p>}
      {okr.Status === 'Failed' && okr.failureDate && <p>Date Marked Failed: {formatDate(okr.failureDate)}</p>}
      <p>Comments: {okr.Comments || 'No comments'}</p>
    </div>
  );
}

export default FokusOKRTile;
