import React, { useState, useEffect } from 'react';
import axios from 'axios';
import supabase from '../Auth/supabase';
import '../components/MyOKRs.css';
import MyOKRTile from '../components/MyOKRTile';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

const MyOKRs: React.FC = () => {
  const [userOKRs, setUserOKRs] = useState<OKR[]>([]);
  const [upcomingOKRs, setUpcomingOKRs] = useState<OKR[]>([]);
  const [overdueOKRs, setOverdueOKRs] = useState<OKR[]>([]);
  const [teamOKRs, setTeamOKRs] = useState<OKR[]>([]);
  const [error, setError] = useState<string | null>(null);

  const isUpcomingOrOverdue = (deadlineString: string): { overdue: boolean, upcoming: boolean } => {
    const today = new Date();
    const deadline = new Date(deadlineString);
    const timeDiff = deadline.getTime() - today.getTime();
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    return {
      overdue: daysDiff < 0,
      upcoming: daysDiff >= 0 && daysDiff <= 14,
    };
  };

  useEffect(() => {
    const fetchOKRs = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const token = session?.access_token;

        if (token) {
          const response = await axios.get(`${apiBaseUrl}/api/okrs?role=individual`, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          });

          if (Array.isArray(response.data)) {
            const allOkrs = response.data;

            const upcomingOkrs = allOkrs.filter(okr => okr.TimePeriod && isUpcomingOrOverdue(okr.TimePeriod).upcoming);
            const overdueOkrs = allOkrs.filter(okr => okr.TimePeriod && isUpcomingOrOverdue(okr.TimePeriod).overdue);
            const teamOkrsFiltered = allOkrs.filter(okr => okr.Team === 'Team Name'); // Replace with actual team logic

            setUserOKRs(allOkrs);
            setUpcomingOKRs(upcomingOkrs);
            setOverdueOKRs(overdueOkrs);
            setTeamOKRs(teamOkrsFiltered);
          } else {
            setError("Error fetching OKRs");
          }
        } else {
          setError("No auth token found");
        }
      } catch (error) {
        console.error("Failed to fetch OKRs:", error);
        setError("Failed to fetch OKRs");
      }
    };

    fetchOKRs();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="my-okrs-page">
      <div className="left-column">
        <h2>My OKRs</h2>
        {userOKRs.length > 0 ? (
          <div className="okr-list">
            {userOKRs.map((okr, index) => (
              <MyOKRTile key={index} okr={okr} />
            ))}
          </div>
        ) : (
          <p>Currently no OKRs assigned.</p>
        )}
      </div>

      <div className="right-column">
        <div className="top-right">
          <h2>Deadlines</h2>

          <h3>Overdue</h3>
          {overdueOKRs.length > 0 ? (
            <ul className="okr-list">
              {overdueOKRs.map((okr, index) => (
                <li key={index}>{okr.Goal || 'No Goal Title'}</li>
              ))}
            </ul>
          ) : (
            <p>No overdue OKRs.</p>
          )}

          <h3>Deadline in next 14 days</h3>
          {upcomingOKRs.length > 0 ? (
            <ul className="okr-list">
              {upcomingOKRs.map((okr, index) => (
                <li key={index}>{okr.Goal || 'No Goal Title'}</li>
              ))}
            </ul>
          ) : (
            <p>No upcoming deadlines.</p>
          )}
        </div>

        <div className="bottom-right">
          <h2>Team OKRs</h2>
          {teamOKRs.length > 0 ? (
            <div className="okr-list">
              {teamOKRs.map((okr, index) => (
                <MyOKRTile key={index} okr={okr} />
              ))}
            </div>
          ) : (
            <p>No team OKRs found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyOKRs;
