import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import StatusPieChart from './PieChart';
import './MainCards.css';
import OKRTile from './OKRTile';
import colors from './color';

interface OKR {
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

interface MainCardsLayoutProps {
  goalType: string;
  selectedOkrs: OKR[];
  onStatusClick: (statusName: string) => void;
  selectedStatus: string | null;
  selectedTeam: string;
  selectedUser: string;
}

export function OKROverviewCard({ goalType, selectedOkrs, selectedTeam }: MainCardsLayoutProps) {
  const filteredOkrs = selectedOkrs.filter(okr => 
    okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );
  let title = `${goalType} OKR Overview`;
  if (goalType === 'Team' && selectedTeam !== 'All Teams') {
    title = `${selectedTeam} Team OKR Overview`;
  }

  return (
    <Card className="card">
      <CardContent>
        <Typography variant="h5" style={{ fontSize: '24px' }}>{title}</Typography>
        {filteredOkrs.length > 0 ? (
          filteredOkrs.map((okr, index) => (
            <Typography key={index} variant="body1" style={{ marginTop: '8px' }}>
              {okr.Goal}
            </Typography>
          ))
        ) : (
          <Typography variant="body2" style={{ marginTop: '8px' }}>
            No OKRs found.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

function OKRStatusOverviewCard({ selectedOkrs, onStatusClick, selectedTeam, selectedUser }: MainCardsLayoutProps) {
  const filteredOkrs = selectedOkrs.filter(okr => 
    (selectedTeam === 'All Teams' || okr.Team === selectedTeam) &&
    (!selectedUser || okr.AssigneeName === selectedUser) &&
    okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );

  return (
    <Card className="card">
      <CardContent>
        <Typography variant="h5" style={{ fontSize: '24px' }}>OKR Status Overview</Typography>
        <StatusPieChart okrs={filteredOkrs} onStatusClick={onStatusClick} />
      </CardContent>
    </Card>
  );
}

export function OKRDetailsCard({ selectedOkrs, selectedStatus }: MainCardsLayoutProps) {
  const statusFilteredOkrs = selectedOkrs.filter(okr => 
    (selectedStatus ? okr.Status === selectedStatus : true) && 
    okr.Goal &&
    okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );
  const titleColor = selectedStatus ? colors[selectedStatus] || colors.Black : colors.Black;

  return (
    <Card className="card">
      <CardContent>
        <Typography variant="h5" style={{ color: titleColor, fontSize: '24px' }}>
          {selectedStatus ? `${selectedStatus} OKRs` : "OKR Details"}
        </Typography>
        {statusFilteredOkrs.length > 0 ? (
          statusFilteredOkrs.map((okr, index) => (
            <OKRTile
              key={index}
              goal={okr.Goal}
              owner={okr.AssigneeName}
              timePeriod={okr.TimePeriod}
              showOwner={okr.GoalType !== 'Company'}
            />
          ))
        ) : (
          <Typography variant="body2" style={{ marginTop: '8px', fontSize: '16px' }}>
            No OKRs found for this status.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export const MainCardsLayout: React.FC<MainCardsLayoutProps> = ({ goalType, selectedOkrs, onStatusClick, selectedStatus, selectedTeam, selectedUser }) => {
  const [detailStatus, setDetailStatus] = useState<string | null>(null);

  useEffect(() => {
    setDetailStatus(null);
  }, [goalType]);

  const handlePieChartStatusClick = (status: string) => {
    setDetailStatus(status);
    onStatusClick(status);
  };

  return (
    <div className="cards-container">
      <div className="left-card">
        <OKROverviewCard
          goalType={goalType}
          selectedOkrs={selectedOkrs}
          selectedTeam={selectedTeam}
          selectedStatus={selectedStatus}
          onStatusClick={handlePieChartStatusClick}
          selectedUser={selectedUser}
        />
      </div>
      <div className="right-cards">
        <OKRStatusOverviewCard
          goalType={goalType}
          selectedOkrs={selectedOkrs}
          selectedStatus={selectedStatus}
          onStatusClick={handlePieChartStatusClick}
          selectedTeam={selectedTeam}
          selectedUser={selectedUser}
        />
        <OKRDetailsCard
          goalType={goalType}
          selectedOkrs={selectedOkrs}
          selectedStatus={detailStatus}
          onStatusClick={handlePieChartStatusClick}
          selectedTeam={selectedTeam}
          selectedUser={selectedUser}
        />
      </div>
    </div>
  );
}
