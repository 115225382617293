import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FokusOKRTile from '../components/FokusOKRTiles';
import supabase from '../Auth/supabase';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  _id: string;
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

const PreviousOKRs: React.FC = () => {
  const [okrs, setOkrs] = useState<OKR[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOKRs = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const token = session?.access_token;

        if (token) {
          const response = await axios.get<OKR[]>(`${apiBaseUrl}/api/okrs`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          console.log("API Response:", response.data);
          
          if (Array.isArray(response.data)) {
            setOkrs(response.data);
          } else {
            console.error("Expected an array but received:", response.data);
            setOkrs([]); // Fallback to an empty array
          }
        } else {
          console.error("No auth token found");
          setError("No auth token found");
        }
      } catch (error) {
        console.error("Failed to fetch OKRs:", error);
        setError("Failed to fetch OKRs");
      }
    };
  
    fetchOKRs();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  const completedOkrs = okrs.filter(okr => okr.Status === 'Completed');
  const failedOkrs = okrs.filter(okr => okr.Status === 'Failed');

  return (
    <div style={{ display: 'flex', paddingLeft: '250px', paddingRight: '30px', paddingTop: '20px', gap: '10px' }}>
      <div style={{ flex: 1 }}>
        <h2>Completed OKRs</h2>
        {completedOkrs.length > 0 ? completedOkrs.map(okr => <FokusOKRTile key={okr._id} okr={okr} />) : <p>No completed OKRs found.</p>}
      </div>
      <div style={{ flex: 1 }}>
        <h2>Failed OKRs</h2>
        {failedOkrs.length > 0 ? failedOkrs.map(okr => <FokusOKRTile key={okr._id} okr={okr} />) : <p>No failed OKRs found.</p>}
      </div>
    </div>
  );
}

export default PreviousOKRs;
