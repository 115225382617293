import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import Sidebar from './components/Navbar';
import Dashboard from './Pages/Dashboard';
import OKRRelationships from './Pages/OKRRelationship';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import FocusOKRs from './Pages/FokusOKR';
import MyOKRs from './Pages/MyOKRs'; 
import PreviousOKRs from './Pages/PreviousOKR';
import LoginPage from './LoginPage';
import PrivateRoute from './PrivateRoute';
import SignUp from './Auth/Signup';
import ForgotPassword from './Auth/ForgotPassword';
import PasswordResetSuccess from './Auth/PasswordResetSuccess';
import ResetPassword from './Auth/ResetPassword'; // Import Reset Password page
import supabase from './Auth/supabase'; // Import Supabase client


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

function App() {
  const [okrs, setOkrs] = useState<OKR[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedOkrs, setSelectedOkrs] = useState<OKR[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [authCompleted, setAuthCompleted] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOKRs = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/okrs`);
        const okrsData = Array.isArray(response.data) ? response.data : [];
        setOkrs(okrsData);
        const filteredOkrs = okrsData.filter((okr: OKR) => okr.Status === 'Green');
        setSelectedOkrs(filteredOkrs);
      } catch (error) {
        console.error('There was an error fetching the OKRs:', error);
      }
    };
  
    fetchOKRs();
  }, []); // Only run this effect once when the component mounts
  

  useEffect(() => {
    const checkSession = async () => {
      const { data: session } = await supabase.auth.getSession();

      setIsAuthenticated(!!session);
      setAuthCompleted(true);
    };

    checkSession();
  }, []);

  const handleStatusClick = (statusName: string) => {
    const filteredOkrs = okrs.filter(okr => okr.Status === statusName);
    setSelectedOkrs(filteredOkrs);
    setSelectedStatus(statusName);
  };

  const logout = async () => {
    await supabase.auth.signOut();
    localStorage.removeItem('authToken');
    navigate('/login');
    window.location.reload(); // Reload the page to clear the cached data
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={
            <>
              <Sidebar logout={logout} />
              <Dashboard
                okrs={okrs}
                onStatusClick={setSelectedStatus}
                selectedOkrs={selectedOkrs}
                selectedStatus={selectedStatus}
              />
            </>
          } />
          <Route path="/my-okrs" element={
            <>
              <Sidebar logout={logout} />
              <MyOKRs />  {/* Add the new MyOKRs page here */}
              </>
          } />
          <Route path="/okr-relationships" element={
            <>
              <Sidebar logout={logout} />
              <OKRRelationships />
            </>
          } />
          <Route path="/focus-okrs" element={
            <>
              <Sidebar logout={logout} />
              <FocusOKRs />
            </>
          } />
          <Route path="/previous-okrs" element={
            <>
              <Sidebar logout={logout} />
              <PreviousOKRs />
            </>
          } />
        </Route>
      </Routes>
    </div>
  );
}


export default App;