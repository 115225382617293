import React from 'react';
import { format, parseISO } from 'date-fns';

interface OKR {
  Goal: string;
  TimePeriod?: string;
  Comments?: string;
  Status: string;

}

interface MyOKRTileProps {
  okr: OKR;
}

const MyOKRTile: React.FC<MyOKRTileProps> = ({ okr }) => {
  // Helper function to format the date
  const formatDate = (dateString: string): string => {
    const date = parseISO(dateString);
    return format(date, 'd-MMM-yyyy');
  };

  return (
    <div className="my-okrtile">
      <h3>{okr.Goal}</h3>
      <p><strong>Deadline:</strong> {okr.TimePeriod ? formatDate(okr.TimePeriod) : 'No deadline'}</p>
      <p><strong>Status:</strong> {okr.Status || 'No status update'}</p>
      <p><strong>Comments:</strong> {okr.Comments || 'No comments'}</p>
    </div>
  );
};

export default MyOKRTile;
