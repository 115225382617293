import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FokusOKRTile from '../components/FokusOKRTiles';
import supabase from '../Auth/supabase';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

interface OKR {
  _id: string;
  Goal: string;
  GoalType: string;
  Team: string;
  AssigneeId?: string;
  AssigneeName: string;
  TimePeriod: string;
  Status: string;
  Comments?: string;
  ParentGoalId?: string;
  CompletionDate?: string;
  FailureDate?: string;
}

function FocusOKRs() {
  const [okrs, setOkrs] = useState<OKR[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOKRs = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const token = session?.access_token;

        if (token) {
          const response = await axios.get<OKR[]>(`${apiBaseUrl}/api/okrs`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setOkrs(response.data);
        } else {
          console.error("No auth token found");
          setError("No auth token found");
        }
      } catch (error) {
        console.error("Failed to fetch OKRs:", error);
        setError("Failed to fetch OKRs");
      }
    };
  
    fetchOKRs();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  const redOkrs = okrs.filter(okr => okr.Status === 'Red');
  const amberOkrs = okrs.filter(okr => okr.Status === 'Amber');

  return (
    <div style={{ display: 'flex', paddingLeft: '250px', paddingRight: '30px', paddingTop: '20px', gap: '10px' }}> 
      <div style={{ flex: 1 }}>
        <h2>Red OKRs</h2>
        {redOkrs.map(okr => <FokusOKRTile key={okr._id} okr={okr} />)}
      </div>
      <div style={{ flex: 1 }}>
        <h2>Amber OKRs</h2>
        {amberOkrs.map(okr => <FokusOKRTile key={okr._id} okr={okr} />)}
      </div>
    </div>
  );
}

export default FocusOKRs;
