import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Select, MenuItem, FormControl } from '@mui/material';
import axios from 'axios';
import { SelectChangeEvent } from '@mui/material/Select';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

interface Team {
  text: string;
  value: string;
}

interface User {
  name: string;
}

interface TopCardsProps {
  setGoalType: React.Dispatch<React.SetStateAction<string>>;
  selectedGoalType: string;
  setSelectedTeam: React.Dispatch<React.SetStateAction<string>>;
  selectedTeam: string;
  setSelectedUser: React.Dispatch<React.SetStateAction<string>>;
  selectedUser: string;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string | null>>;
}

const TopCards: React.FC<TopCardsProps> = ({
  setGoalType,
  selectedGoalType,
  setSelectedTeam,
  selectedTeam,
  setSelectedUser,
  selectedUser,
  setSelectedStatus
}) => {
  const [showTeamDropdown, setShowTeamDropdown] = useState<boolean>(false);
  const [showUserDropdown, setShowUserDropdown] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [prevGoalType, setPrevGoalType] = useState<string>(selectedGoalType);

  const teams: Team[] = [
    { text: 'All Teams', value: 'All Teams' },
    { text: 'Sales', value: 'Sales' },
    { text: 'Engineering', value: 'Engineering' },
    { text: 'Data Science', value: 'Data Science' },
    { text: 'Product', value: 'Product' },
    { text: 'Finance', value: 'Finance' },
    { text: 'HR', value: 'HR' },
    { text: 'Communication', value: 'Communication' },
    { text: 'Legal', value: 'Legal' }
  ];

  useEffect(() => {
    setShowTeamDropdown(selectedGoalType === 'Team');
    setShowUserDropdown(selectedGoalType === 'Individual');

    if (selectedGoalType !== prevGoalType) {
      setSelectedTeam('All Teams');
      setSelectedStatus(null);
      setSelectedUser('');
      setPrevGoalType(selectedGoalType);
    }

    if (selectedGoalType === 'Individual') {
      axios.get(`${apiBaseUrl}/api/okr-owners`)
        .then(response => {
          setUsers(response.data);
        })
        .catch(error => console.error("Failed to fetch users:", error));
    }
  }, [selectedGoalType, prevGoalType, setSelectedTeam, setSelectedStatus, setSelectedUser]);

  const handleCardClick = (type: string) => {
    const typeMap: { [key: string]: string } = { 'Company OKRs': 'Company', 'Team OKRs': 'Team', 'Individual OKRs': 'Individual' };
    setGoalType(typeMap[type]);
  };

  const handleTeamChange = (event: SelectChangeEvent<string>) => {
    setSelectedTeam(event.target.value as string);
  };

  const handleUserChange = (event: SelectChangeEvent<string>) => {
    setSelectedUser(event.target.value as string);
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '0px',
      padding: '20px'
    }}>
      {['Company OKRs', 'Team OKRs', 'Individual OKRs'].map((title, index) => (
        <Card key={index} style={{
          width: '26.3%',
          minHeight: '100px',
          marginLeft: index === 0 ? '15%' : '2%',
          cursor: 'pointer'
        }}
          onClick={() => handleCardClick(title)}>
          <CardContent>
            <Typography variant="h5" component="div">
              {title}
            </Typography>
            {title === 'Team OKRs' && showTeamDropdown && (
              <FormControl fullWidth>
                <Select
                  value={selectedTeam}
                  onChange={handleTeamChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {teams.map((team, idx) => (
                    <MenuItem key={idx} value={team.value}>{team.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {(title === 'Individual OKRs' && showUserDropdown) && (
              <FormControl fullWidth>
                <Select
                  value={selectedUser || ''}
                  onChange={handleUserChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}>
                  {users.map((user, idx) => (
                    <MenuItem key={idx} value={user.name}>{user.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

export default TopCards;
